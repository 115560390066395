function add(stream) {
  const {
    id, userid, service, start,
  } = stream;

  return fetch(`/stream/${id}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Charset': 'utf-8',
    },
    body: JSON.stringify({
      id, userid, service, start,
    }),
  })
    .catch((err) => {
      console.log('Stream add analytics error', err);
    });
}

function update(id, data) {
  return fetch(`/stream/${id}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Charset': 'utf-8',
    },
    body: JSON.stringify(data),
  })
    .catch((err) => {
      console.log('Stream update analytics error', err);
    });
}

module.exports = {
  add,
  update,
};
