const twitch = require('./twitch');
const youtube = require('./youtube');

function start(streamId, user) {
  const { type } = user;
  const clients = [];
  if (type.includes('youtube')) {
    clients.push(new Promise((resolve, reject) => {
      youtube.start(user)
        .then(() => {
          resolve(youtube);
        })
        .catch(reject);
    }));
  }

  if (type.includes('twitch')) {
    clients.push(new Promise((resolve) => {
      twitch.start(streamId);
      resolve(twitch);
    }));
  }

  return clients;
}

module.exports = {
  start,
};
