<template>
  <div>
    <Video v-if="!error" :streamId="streamId" :sentiment="sentiment" :userId="userId" />
    <div v-else class="error">
      {{error}}
    </div>
  </div>
</template>

<script>
import Video from '@/components/Video.vue';

const hre = require('../hre.js');
const chat = require('../chat');
const { streams, chats } = require('../analytics');

const videos = require('../content/videos.json');

export default {
  name: 'Stream',
  components: {
    Video,
  },
  data() {
    return {
      started: false,
      open: false,
      sentiment: 0,
      client: null,
      user: null,
      streamId: null,
      videos,
      error: null,
    };
  },
  computed: {
    userId() {
      if (this.user) {
        const type = this.user.type || 'twitch';
        return type === 'youtube' ? this.user.youtube_id : this.user.twitch_id;
      }

      return null;
    },
  },
  methods: {
    onSentiment(sentiment) {
      this.sentiment = sentiment;
    },
    getUser() {
      return fetch('/user', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Charset': 'utf-8',
        },
      })
        .then((res) => res.json())
        .then((res) => {
          this.user = res;

          if (res.allow_custom) {
            this.videos.push({
              id: 'custom',
              title: 'Custom reaction',
              video: 'gifs/add-card.png',
              sentiment: 'custom',
              keywords: res.keywords.custom || [],
              length: 3000,
            });
          }
          return res;
        });
    },
    getSentiment(id) {
      return videos.find((v) => v.id === id);
    },
    setKeywords(keywords) {
      if (keywords) {
        Object.keys(keywords).forEach((key) => {
          const sentimentObj = this.getSentiment(key);
          const words = keywords[key];
          if (sentimentObj) {
            hre.addKeyword(key, sentimentObj.sentiment, words);
          }
        });
      }
    },
    sendStart() {
      return fetch('/streamStart', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Charset': 'utf-8',
        },
      })
        .then(() => {
          console.log('Start stream email sent');
        });
    },
    start() {
      const self = this;
      this.sendStart();
      // todo: remove once login is all set
      this.getUser()
        .then((user) => {
          const { keywords } = user;
          this.setKeywords(keywords);

          return fetch('/streamId', {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Accept-Charset': 'utf-8',
            },
          });
        })
        .then((res) => res.json())
        .then((res) => {
          const id = `${self.user.id}-${Date.now()}`;
          const { streamId } = res;
          self.streamId = id;
          return Promise.all(chat.start(streamId, self.user));
        })
        .then((clients) => {
          console.log('clients', clients);
          const time = new Date().toLocaleString('en-US');
          self.clients = clients;
          self.clients.forEach((client) => client.onMessage(self.parseChat));
          hre.start();
          hre.onSentiment(self.onSentiment);
          streams.add({
            id: self.streamId,
            userid: self.user.id,
            service: self.user.type.toString(),
            start: time,
          });
          window.addEventListener('beforeunload', self.endStream);
        })
        .catch(this.handleError);
    },
    endStream() {
      window.removeEventListener('beforeunload', this.endStream);
      streams.update(this.streamId, {
        end: new Date().toLocaleString('en-US'),
      });
    },
    async parseChat(message, data, isDonation) {
      if (message.error) {
        this.handleError(message);
        return;
      }

      const val = await hre.addMessage(message, isDonation);
      Object.assign(data, val);
      data.stream = this.streamId;
      chats.add(data)
        .catch(this.handleError);
    },
    handleError(err) {
      console.log('-------- CHAT ERROR --------');
      console.log(err);
      if (err.message === 'No broadcast found') {
        this.error = 'No broadcast found, please restart when ready to stream';
      } else {
        this.error = 'An error has occurred, try again later';
      }
    },
  },
  mounted() {
    this.start();
  },
  beforeDestroy() {
    this.endStream();
  },
};
</script>

<style scoped lang="scss">
</style>
