const tmi = require('tmi.js');

let messageListener;
let client;

// Define configuration options
const opts = {
  identity: {
    username: process.env.TWITCH_UN,
    // password: process.env.TWITCH_KEY,
  },
  channels: [],
};

// Called every time the bot connects to Twitch chat
function onConnectedHandler(addr, port) {
  console.log(`* Connected to ${addr}:${port}`);
}

function onDisconnectedHandler(reason) {
  console.log('Disconnected: ', reason);
}

// Called every time a message comes in
function onMessageHandler(target, context, msg, self) {
  if (self) { return; } // Ignore messages from the bot

  // Remove whitespace from chat message
  const commandName = msg.trim();
  const time = parseInt(context['tmi-sent-ts'], 10);
  const data = {
    id: context.id,
    name: context.username,
    msg: commandName,
    time: new Date(time).toLocaleString('en-US'),
    provider: 'twitch',
  };

  if (messageListener) {
    messageListener(commandName, data);
  }
}

function start(id) {
  opts.channels.push(id);
  client = new tmi.client(opts);

  client.on('message', onMessageHandler);
  client.on('connected', onConnectedHandler);
  client.on('disconnected', onDisconnectedHandler);

  client.connect();
  return client;
}

function end() {
  if (client && client.disconnect) {
    client.disconnect();
    client = null;
  }
}

function onMessage(cb) {
  messageListener = cb;
}

module.exports = {
  start,
  end,
  onMessage,
};
