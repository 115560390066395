<template>
  <div>
    <div class="center margin-bottom-large gutters margin-top-large">
      <h1>Stream Set Up</h1>
      <p class="intro">Once you're ready to begin, press the start button to open the avatar in a new window. In your streaming software, add a Browser source with the window's URL. You may need to login through the streaming software's browser.</p>

      <div class="margin-bottom-small center-horizontal">
        <div>
          <div class="center-vertical margin-bottom-small">
            <input type="checkbox" id="twitch-checkbox" name="twitch-checkbox" class="switch twitch-switch" @click.prevent="() => handleStreamInput('twitch')" v-model="streamingTwitch" />
            <label v-if="streamingTwitch" for="twitch-checkbox">Streaming with Twitch</label>
            <label v-else for="twitch-checkbox">Stream with Twitch</label>
          </div>

          <div class="center-vertical margin-bottom-small">
            <input type="checkbox" id="youtube-checkbox" name="youtube-checkbox" class="switch youtube-switch" @click.prevent="() => handleStreamInput('youtube')" v-model="streamingYoutube" />
            <label v-if="streamingYoutube" for="youtube-checkbox">Streaming with YouTube</label>
            <label v-else for="youtube-checkbox">Stream with YouTube</label>
          </div>
        </div>
      </div>

      <button v-if="started" @click="end" class="button large">End</button>
      <button v-else @click="start" :disabled="!canStream" class="button large">Start</button>

      <!-- <div class="margin-top-small">
        <input type="text" ref="streamId" :value="streamId" placeholder="Channel for testing" />
      </div> -->
    </div>

    <div>
      <div class="keyword-start">
        <h2>Your custom keywords</h2>
        <p>Add any unique keywords you would like the avatar to respond to.</p>
      </div>
      <div v-if="cards.length > 0 && open" class="cards" id="cards-container">
        <div v-for="card in cards" :data-emotion="card.id" :key="card.id" class="card">
          <div class="card-asset">
            <img v-if="card.video" :src="card.video" :alt="'Mayor wang animation ' + card.id" @click.stop="toggleDrawer" />
          </div>
          <div class="card-content invert">
            <h3 v-if="card.title" class="drawer-button" @click="(e) => toggleDrawer(e, card.id)">
              <div>{{card.title}}</div> <div class="drawer-toggle" />
            </h3>

            <div class="card-drawer">
              <div v-if="card.keywords.length > 0"  class="keywords scroller">
                <button v-for="keyword in card.keywords" :key="keyword" @click="removeKeyword(card.id, keyword)" class="keyword button-pill">
                  <div>{{keyword}}</div>
                  <div class="keyword-remove">x</div>
                </button>
              </div>
              <div class="keyword-container">
                <input type="text" class="keyword-input" :ref="'input-' + card.id" placeholder="Enter keyword" @keyup.enter="addKeyword(card.id)" />
                <button class="button" :data-emotion="card.id" @click="addKeyword(card.id)">Add</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" :class="{'hidden': !stopPrompt}">
      <div class="screen" @click="closePrompt" />
      <div class="modal-content" v-if="user && user.type.length > 1">
        <h3>Are you sure you want to stop streaming with {{stopPrompt}}?</h3>
        <div class="modal-buttons">
          <button class="button margin-right-small" @click="() => logout(stopPrompt)">Stop stream</button>
          <button class="button light" @click="closePrompt">Cancel</button>
        </div>
      </div>
      <div class="modal-content" v-else>
        <h3>You must have at least one stream.</h3>
        <div class="modal-buttons">
          <button class="button" @click="closePrompt">OK</button>
        </div>
      </div>
    </div>

    <div class="modal" :class="{'hidden': !promptHybridAccount}">
      <div class="screen" @click="closePrompt" />
      <div class="modal-content">
        <h3>Do you want to add {{promptHybridAccount}} access to TOONin?</h3>
        <div class="modal-buttons">
          <button class="button margin-right-small" @click="() => login(promptHybridAccount)">Add account</button>
          <button class="button light" @click="closePrompt">Cancel</button>
        </div>
      </div>
    </div>

    <div class="modal" :class="{'hidden': !duplicateKeyword}">
      <div class="screen" @click="closePrompt" />
      <div class="modal-content">
        <h3>You have already added this keyword to the "{{duplicateKeyword}}" sentiment</h3>
        <p>If you would like to add it, first remove it from the "{{duplicateKeyword}}" sentiment.</p>
        <div class="modal-buttons">
          <button class="button" @click="closePrompt">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash.get';

const videos = require('../content/videos.json');

const defaultCards = videos.map((video) => ({ ...video, keywords: [] }));

export default {
  name: 'Setup',
  data() {
    return {
      started: false,
      open: true,
      cards: defaultCards,
      streamId: '',
      user: null,
      openCards: [],
      stopPrompt: null,
      promptHybridAccount: null,
      duplicateKeyword: null,
    };
  },
  computed: {
    streamingTwitch() {
      return this.isStreaming('twitch');
    },
    streamingYoutube() {
      return this.isStreaming('youtube');
    },
    canStream() {
      return get(this.user, 'type', []).length > 0;
    },
  },
  watch: {
    'user.keywords': function (newVal) {
      this.updateList(newVal);
    },
  },
  methods: {
    isStreaming(provider) {
      return get(this.user, 'type', []).includes(provider);
    },
    toggleDrawer(e, sentiment) {
      let elem = e.target;
      if (!elem.classList || !elem.classList.contains('card')) {
        elem = elem.closest('.card');
      }
      const value = elem.classList.contains('open') ? 'close' : 'open';
      elem.classList.toggle('open');
      gtag('event', 'click', { btn: 'keyword_drawer', value, sentiment });
    },
    getKeywords() {
      return fetch('/user', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Charset': 'utf-8',
        },
      })
        .then((res) => res.json())
        .then((res) => {
          this.user = res;
          const keywords = res && res.keywords ? res.keywords : null;
          if (keywords) {
            this.updateList(keywords);
          }

          if (res.allow_custom && !this.cards.find((card) => card.id === 'custom')) {
            this.cards.push({
              id: 'custom',
              sentiment: 'custom',
              title: 'Custom reaction',
              video: 'gifs/add-card.png',
              keywords: keywords.custom || [],
              length: 3000,
            });
          }

          return keywords;
        });
    },
    addCards() {
      this.open = !this.open;
    },
    addKeyword(sentimentID) {
      const self = this;
      const input = this.$refs[`input-${sentimentID}`][0];
      const keyword = input ? input.value : null;
      if (!keyword || keyword.length <= 0) return;
      const currentKeywords = this.user.keywords;

      if (currentKeywords) {
        const alreadyExists = Object.keys(currentKeywords).find((id) => currentKeywords[id].indexOf(keyword) > -1);

        if (alreadyExists) {
          this.duplicateKeyword = alreadyExists;
          return;
        }
      }

      this.getKeywords()
        .then(() => {
          const keywords = self.user.keywords ? self.user.keywords : {};
          const original = keywords[sentimentID] || [];
          if (original.indexOf(keyword) <= -1) {
            original.push(keyword);
          }

          keywords[sentimentID] = original;
          gtag('event', 'click', { btn: 'keyword_add', value: keyword, sentiment: sentimentID });

          fetch('/keywords', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Accept-Charset': 'utf-8',
            },
            body: JSON.stringify({ keywords }),
          })
            .then((res) => res.json())
            .then((data) => {
              const updated = data && data.keywords ? data.keywords : {};
              this.updateList(updated);
              input.value = '';
            })
            .catch((err) => {
              console.log('keyword add error', err);
            });
        });
    },
    removeKeyword(sentimentID, keyword) {
      const { keywords } = this.user;
      const original = keywords[sentimentID] || [];
      const index = original.indexOf(keyword);
      if (index > -1) {
        original.splice(index, 1);
      }

      keywords[sentimentID] = original;
      gtag('event', 'click', { btn: 'keyword_remove', value: keyword, sentiment: sentimentID });

      fetch('/keywords', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Charset': 'utf-8',
        },
        body: JSON.stringify({ keywords }),
      })
        .then((res) => res.json())
        .then((data) => {
          const updated = data && data.keywords ? data.keywords : {};
          this.updateList(updated);
        })
        .catch((err) => {
          console.log('keyword remove error', err);
        });
    },
    updateList(keywords) {
      this.user.keywords = keywords;
      if (keywords) {
        Object.keys(keywords).forEach((id) => {
          const card = this.cards.find((c) => c.id === id);
          if (card) {
            card.keywords = keywords[id];
          }
        });
      }
    },
    start() {
      const self = this;
      const { streamId } = this.$refs;
      let id;
      if (this.user) {
        id = this.user.type === 'youtube' ? this.user.youtube_id : this.user.twitch_id;
      }
      gtag('event', 'click', { btn: 'stream_start', id });

      if (streamId) {
        fetch('/streamId', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Accept-Charset': 'utf-8',
          },
          body: JSON.stringify({ streamId: streamId.value }),
        })
          .then(() => {
            self.started = true;
            // window.open(`${window.location.origin}/stream`);
            window.location = `${window.location.origin}/stream`;
          });
      } else {
        self.started = true;
        window.location = `${window.location.origin}/stream`;
      }
    },
    getStreamId() {
      const self = this;
      return fetch('/streamId', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Charset': 'utf-8',
        },
      })
        .then((res) => res.json())
        .then((res) => {
          self.streamId = res.streamId;
        });
    },
    end() {
      this.started = false;
    },
    handleStreamInput(provider) {
      if (this.isStreaming(provider)) {
        this.promptLogout(provider);
      } else {
        this.checkLogin(provider);
      }
    },
    login(provider) {
      if (provider === 'twitch') {
        window.location = '/connect/twitch';
      } else if (provider === 'youtube') {
        window.location = '/connect/google';
      }
    },
    checkLogin(provider) {
      if (this.user[`${provider}_id`]) {
        this.login(provider);
      } else {
        this.promptHybridAccount = provider;
      }
    },
    promptLogout(provider) {
      this.stopPrompt = provider;
    },
    closePrompt() {
      this.stopPrompt = null;
      this.promptHybridAccount = null;
      this.duplicateKeyword = null;
    },
    logout(type) {
      const self = this;
      const types = this.user.type;
      types.splice(types.indexOf(type), 1);

      return fetch('/streamType', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Charset': 'utf-8',
        },
        body: JSON.stringify({ type: types }),
      })
        .then((res) => res.json())
        .then((res) => {
          self.user = res;
          self.closePrompt();
        });
    },
  },
  beforeMount() {
    this.getKeywords();
    this.getStreamId();
  },
};
</script>

<style scoped lang="scss">
  @import "../style/_variables.scss";

  .intro {
    max-width: 700px;
    margin: 0 auto;
    margin-bottom: 2rem;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 1rem;
  }

  .card {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    video, img {
      width: 100%;
    }

    &:hover {
      .drawer-button {
        background-color: $color-grey-light;
      }
    }

    &.open {
      .card-drawer {
        max-height: 500px;
      }

      .drawer-button {
        background-color: $color-grey-light;
      }

      .drawer-toggle {
        transform: rotate(180deg);
      }
    }
  }

  .card-asset {
    display: flex;
    background: linear-gradient(#7c29ca, #490786);
  }

  .keyword-start {
    margin-bottom: 2rem;

    h2 {
      margin-bottom: 0;
    }

    p {
      margin-top: 0;
    }
  }

  .keyword-container {
    display: flex;
    align-items: center;
    padding: 0 .5rem .5rem;
  }

  .keywords {
    display: flex;
    flex-wrap: wrap;
    padding: .5rem .5rem 0;
    max-height: 100px;
    overflow-y: auto;
  }

  .keyword {
    margin-right: .5rem;
    margin-bottom: .5rem;
  }

  .keyword-remove {
    // color: #790f79;
    // background-color: transparent;
    // border: none;
    // cursor: pointer;
    margin-left: .5rem;
  }

  .keyword-input {
    flex: 1;
  }

  .video {
    video {
      height: 500px;
    }
  }

  .keywords > div {
    display: flex;
    align-items: center;
  }

  .card-drawer {
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
    background-color: $color-grey-light;
    transition: max-height .5s ease;
  }

  .drawer-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5rem;
    background-color: $color-white;
    cursor: pointer;
    transition: background-color .2s ease;

    &:hover {
      background-color: $color-grey-light;
    }
  }

  .drawer-toggle {
    position: relative;
    width: 1.5rem;
    height: 1rem;
    transition: transform .2s ease;

    &:after,
    &:before {
      content: '';
      background-color: $color-text-light;
      width: 2px;
      height: 100%;
      position: absolute;
      transform-origin: top;
      top: 3px;
    }

    &:before {
      transform: rotate(-45deg);
      left: 0;
    }

    &:after {
      transform: rotate(45deg);
      right: 0;
    }
  }

  .center-vertical {
    display: flex;
    align-items: center;
  }

  .center-horizontal {
    display: flex;
    justify-content: center;
  }
</style>
