function add(chat) {
  return fetch('/chat', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Charset': 'utf-8',
    },
    body: JSON.stringify(chat),
  })
    .catch((err) => {
      console.log('Chat add analytics error', err);
      throw err;
    });
}

module.exports = {
  add,
};
