// const gapi = require('googleapis');
const get = require('lodash.get');

let messageListener;
let nextPage;
let pollingIntervalMillis;
let timeout;
let retries = 0;
const maxRetries = 10;
const retryWait = 8000;
const minPollingInterval = 2000;

function end() {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
}

function getBroadcast() {
  return fetch('/youtube/broadcast')
    .then((res) => res.json())
    .then((res) => {
      const { data } = res;
      const id = get(data, 'items[0].snippet.liveChatId');
      const status = get(data, 'items[0].status.lifeCycleStatus');
      const isLive = status === 'ready' || status === 'live';

      if (!isLive && retries < maxRetries) {
        return new Promise((resolve) => {
          setTimeout(() => {
            retries++;
            resolve(getBroadcast());
          }, retryWait);
        });
      }

      if (id && isLive) {
        return id;
      }

      throw new Error('No broadcast found');
    });
}

function getMessages(id) {
  let url = `/youtube/chat?liveId=${id}`;
  if (nextPage) url += `&nextPage=${nextPage}`;
  return fetch(url)
    .then((res) => res.json())
    .then((res) => {
      const { data } = res;
      if (data.error) {
        throw new Error('Chat error', data.error);
      }

      pollingIntervalMillis = Math.max(data.pollingIntervalMillis, minPollingInterval);
      nextPage = data.nextPageToken;
      return data.items;
    });
}

function parseMessages(msgs) {
  if (!msgs) return;
  msgs.forEach((msg) => {
    const message = get(msg, 'snippet.textMessageDetails.messageText');
    const isDonation = get(msg, 'snippet.superChatDetails.amountMicros') > 0;
    const data = {
      id: get(msg, 'id'),
      name: get(msg, 'authorDetails.displayName'),
      msg: message,
      time: get(msg, 'snippet.publishedAt'),
      provider: 'youtube',
    };
    if (message || isDonation) {
      messageListener(message, data, isDonation);
    }
  });
}

function startPolling(id) {
  timeout = setTimeout(() => {
    getMessages(id)
      .then(parseMessages)
      .then(() => startPolling(id))
      .catch((err) => {
        console.log('Get yt chat error', err);
        end();
        messageListener({
          error: true,
          message: err,
        });
        throw err;
      });
  }, pollingIntervalMillis);
}

function startListening(id) {
  getMessages(id)
    .then((msgs) => {
      startPolling(id);
      parseMessages(msgs);
    });
}

function start() {
  return getBroadcast()
    .then(startListening)
    .catch((err) => {
      console.log('yt start error', err);
      end();
      throw err;
    });
}

function onMessage(cb) {
  messageListener = cb;
}

module.exports = {
  start,
  end,
  onMessage,
};
