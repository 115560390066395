<template>
  <!-- <video id="video" :src="currentVideo" autoplay loop muted /> -->
  <img :src="card.video" :alt="'Mayor Wang cat animation ' + card.id" />
</template>

<script>
const { streams } = require('../analytics');
const videos = require('../content/videos.json');

const DEFAULT_VIDEO = videos.find((v) => v.sentiment === 0);

export default {
  name: 'Video',
  props: ['sentiment', 'streamId', 'userId'],
  data() {
    return {
      started: false,
      open: false,
      timer: null,
      currentVideo: DEFAULT_VIDEO,
      card: DEFAULT_VIDEO,
    };
  },
  watch: {
    sentiment(newVal, oldVal) {
      if (newVal !== oldVal) {
        let card;
        if (isNaN(this.sentiment)) {
          card = videos.find((v) => v.sentiment === this.sentiment);
        } else {
          card = this.getVideo(this.sentiment);
        }

        gtag('event', 'sentiment', {
          value: newVal,
          streamId: this.streamId,
          userId: this.userId,
          sentiment: card.id,
        });

        streams.update(this.streamId, {
          sentiment: JSON.stringify({
            value: card.id,
            time: Date.now(),
          }),
        });

        if (card !== this.card) {
          this.startTimer(card);
          this.currentVideo = card || DEFAULT_VIDEO;
        }
      }
    },
  },
  methods: {
    getVideo(sentiment) {
      return videos.reduce((prev, curr) => {
        const val = prev.sentiment;
        return (Math.abs(curr.sentiment - sentiment) < Math.abs(val - sentiment) ? curr : prev);
      });
    },
    startTimer(card) {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.startTimer(this.currentVideo);
        this.updateVideo();
      }, card.length);
    },
    updateVideo() {
      this.card = this.currentVideo;
    },
  },
  mounted() {
    this.updateVideo();
  },
};
</script>
